<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Profile</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper"></div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper"></div>
      </el-col>
    </el-row>
    <el-card>
      <div class="table_wrapper">
        <table class="table" border="1" width="100%">
          <tbody>
            <tr>
              <th>
                <p>ID :</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Username:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Name:</p>
              </th>
              <td>
                <p></p>
              </td>
            </tr>

            <tr>
              <th>
                <p>Phone:</p>
              </th>
              <td>
                
                <p></p>
              </td>
              <th>
                <p>Email:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Type:</p>
              </th>
              <td>
                <p></p>
              </td>
            </tr>

            <tr>
              <th>
                <p>MCX Holding Margin per Lot:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Equity brokerage per crore:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Intraday Exposure/Margin Equity:</p>
              </th>
              <td>
                <p></p>
              </td>
            </tr>

            <tr>
              <th>
                <p>Holding Exposure/Margin Equity:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Ledger Balance:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Account Created At:</p>
              </th>
              <td>
                <p></p>
              </td>
            </tr>

            <tr>
              <th>
                <p>Valid Till:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Maximum number of clients to be allowed:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Maximum number of brokers / office-staff to be allowed:</p>
              </th>
              <td>
                <p></p>
              </td>
            </tr>

            <tr>
              <th>
                <p>Total Earnings:</p>
              </th>
              <td>
                <p></p>
              </td>
              <th>
                <p>Account Status :</p>
              </th>
              <td>
                <p></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div class="menu_wrapper">
         <el-row :gutter="20">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>ID : 001</p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>Username : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>First Name : </p>
          </el-col>
         </el-row>

          <el-row :gutter="20">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>Last Name : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>Phone : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>Email : </p>
          </el-col>
          </el-row>

          <el-row :gutter="20">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>Type : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>MCX brokerage per crore : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>MCX Intraday Margin per Lot : </p>
          </el-col>
          </el-row>

          <el-row :gutter="20">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
            <p>MCX Holding Margin per Lot : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Equity brokerage per crore : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Intraday Exposure/Margin Equity : </p>
          </el-col>
          </el-row>

          <el-row :gutter="20">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Holding Exposure/Margin Equity : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Ledger Balance : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Account Created At : </p>
          </el-col>
          </el-row>
          <el-row :gutter="20">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Valid Till : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Maximum number of clients to be allowed. : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Maximum number of brokers / office-staff to be allowed. : </p>
          </el-col>
          </el-row>
          <el-row :gutter="20">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Total Earnings : </p>
          </el-col>
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
             <p>Account Status : </p>
          </el-col>
          </el-row>
    </div> -->
    </el-card>
    <br />

    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Child Brokers</h4>
      </el-col>
    </el-row>
    <Table
      tableKey="childBrokers"
      :tableConfig="config"
      :tableData="listDataChildBrokers"
      :tableQuery="listQuery"
      :tableActionVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
    />
    <br />
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Fund - Withdrawal & Deposits</h4>
      </el-col>
    </el-row>
    <Table
      tableKey="closePositions"
      :tableConfig="config2"
      :tableData="listDataFund"
      :tableQuery="listQuery"
      :tableActionVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";

export default {
  name: "profile",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      config: [
        {
          label: "ID",
          prop: "id",
          width: "100",
          className: "redFont",
        },
        {
          label: "Username",
          prop: "user_name",
          width: "",
          className: "blueFont",
        },
        {
          label: "Credit Limit",
          prop: "credit_limit",
          width: "130",
          className: "blueFont",
        },
        {
          label: "Ledger Balance",
          prop: "leager_balance",
          width: "170",
          className: "blueFont",
        },
        {
          label: "Gross PI",
          prop: "gross_pi",
          width: "",
          className: "blueFont",
        },
        {
          label: "Brokerage",
          prop: "brokerage",
          width: "",
          className: "blueFont",
        },
        {
          label: "Net PI",
          prop: "net_pi",
          width: "",
          className: "blueFont",
        },
        {
          label: "Type",
          prop: "type",
          width: "",
          className: "blueFont",
        },
        {
          label: "Account Status",
          prop: "account_status",
          width: "170",
          className: "blueFont",
        },
      ],
      config2: [
        {
          label: "Fund",
          prop: "fund",
          width: "",
          className: "redFont",
        },
        {
          label: "Created At",
          prop: "created_at",
          width: "",
          className: "blueFont",
        },
        {
          label: "Notes",
          prop: "notes",
          width: "",
          className: "blueFont",
        },
      ],
      listDataChildBrokers: {},
      listDataFund: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      data: {
        data: {
          item: [
            {
              id: "962",
              user_name: "VIM001",
              credit_limit: "00",
              leager_balance: "00",
              gross_pi: "00",
              brokerage: "00",
              net_pi: "00",
              type: "sub-broker",
              account_status: "Active",
            },
          ],
        },
      },
      dataFund: {
        data: {
          item: [
            {
              fund: "0",
              created_at: "0",
              notes: "notes",
            },
          ],
        },
      },
    };
  },
  computed: {},
  created() {
    this.getList();
    this.getListFund();
  },
  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList() {
      this.listDataChildBrokers = this.data;
      console.log(this.listDataChildBrokers);
    },
    getListFund() {
      this.listDataFund = this.dataFund;
      console.log(this.listDataFund);
    },
  },
};
</script>
